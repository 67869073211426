<template>
  <v-footer
    id="dashboard-core-footer" absolte
  >
    <v-container>
      <v-row
        align="center"
        no-gutters
      >
        <v-col
          v-for="(link, i) in links"
          :key="i"
          class="text-center mb-sm-0 mb-5"
          cols="auto"
        >
          <a
            :href="link.href"
            class="mr-0"
            rel="noopener"
            target="_blank"
            v-text="link.text"
          />
        </v-col>

        <v-spacer class="hidden-sm-and-down" />

        <v-col
          cols="12"
          md="auto"
        >
          <!--<div class="body-1 font-weight-light pt-6 pt-md-0 text-center">-->
          <div class="pt-6 pt-md-0 text-center">
            <!--&copy; 2020-->
            <!-- <a class="mr-0">© 2022 the cxapp.</a> -->
            <a class="mr-0">© 2022 Inpixon.</a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      links: [
        {
          href: 'https://thecxapp.com/privacy-policy',
          text: 'PRIVACY'
        },
        {
          href: 'https://ewls.atlassian.net/servicedesk/customer/portal/2',
          text: 'CONTACT SUPPORT'
        },
        {
          href: '#',
          text: 'V6.0.0'
        }
      ]
    })
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    position: absolute
    background: #999999
    width: 100%
    bottom: 0
    a
      color: #ffffff
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
  .theme--light.v-footer
    background-color: #999999
    color: #ffffff
  .theme--light.v-footer .body-1, .theme--light.v-footer .v-icon
    color: #ffffff
</style>
